import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Container from '~components/Container';
import RichText from '~components/RichText';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton';
import RoundedLinkIconButton from '~components/buttons/RoundedLinkIconButton';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  root: props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    borderRadius: '28px',
    padding: '45px 80px 30px',
    backgroundColor: props.ad_background_color || 'transparent',
    backgroundImage: props.background_image?.url ? `url(${props.background_image.url})` : 'none',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: props.ad_text_color || '#000000',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 22px'
    }
  }),
  title: {
    marginTop: 0,
    marginBottom: 13,
    textAlign: 'center',
    '& > *': {
      fontSize: 24,
      lineHeight: '33px',
      fontFamily: 'Inter',
      fontWeight: '700',
      marginTop: 0,
      marginBottom: 0
    }
  },
  content: {
    marginTop: 0,
    marginBottom: 26,
    textAlign: 'center',
    maxWidth: '375px',
    margin: 'auto',
    opacity: 0.9,
    '& > *': {
      fontSize: 16,
      fontFamily: 'Inter',
      lineHeight: '26px',
      fontWeight: '400',
      marginTop: 8,
      marginBottom: 8
    },
    '& > p:empty': {
      height: '8px'
    },
    '& > p:first-child': {
      marginTop: 0
    }
  }
}));

const getCtaProps = theme => {
  switch (theme) {
    case 'blue-watch-icon': {
      return { variant: 'blue', icon: <PlayArrowIcon fill="#278AE7" height={17} width={17} /> };
    }
    default:
      return { variant: theme };
  }
};

const ArticleInlineAd = ({
  ad_title,
  ad_description,
  ad_text_color,
  ad_background_color,
  background_image,
  cta_path,
  cta_text,
  cta_theme,
  containerSize
}) => {
  const classes = useStyles({ ad_text_color, ad_background_color, background_image });
  const { variant, icon } = getCtaProps(cta_theme);

  return (
    <Container maxWidth={containerSize} className={classes.container} center>
      <div className={classes.root}>
        <RichText externalClassName={classes.title} html={ad_title?.html} />
        {ad_description?.html && (
          <RichText externalClassName={classes.content} html={ad_description?.html} />
        )}
        {cta_path && cta_text && !icon && (
          <RoundedLinkButton text={cta_text} to={cta_path} size="small" variant={variant} />
        )}
        {cta_path && cta_text && icon && (
          <RoundedLinkIconButton
            icon={icon}
            text={cta_text}
            to={cta_path}
            size="small"
            variant={variant}
          />
        )}
      </div>
    </Container>
  );
};

ArticleInlineAd.propTypes = {
  ad_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  ad_description: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  ad_text_color: PropTypes.string,
  ad_background_color: PropTypes.string,
  background_image: PropTypes.PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  cta_path: PropTypes.string,
  cta_text: PropTypes.string,
  cta_theme: PropTypes.oneOf(['blue', 'blue-watch-icon']),
  containerSize: PropTypes.string
};

ArticleInlineAd.defaultProps = {
  ad_text_color: '#000000',
  ad_background_color: 'transparent',
  cta_path: '',
  cta_text: '',
  cta_theme: '',
  background_image: null,
  containerSize: 'md'
};

export default ArticleInlineAd;
